<template>
  <div class="community-detail" style="height: 100%">
    <div class="community-detail-header">

      <div class="community-detail-header-right">
        <div class="top">
          <div class="top-left">
            <h1>{{ detailNews?.infoTitle }}</h1>
            <h4>

              <span>{{ formatDateTime(detailNews.createTime) }}</span>
            </h4>
          </div>

        </div>
        <div class="bottom" v-html="detailNews?.infoContent">

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getNewsDetail } from "@/api/product";
import {mapGetters} from "vuex";
import moment from "moment/moment";

export default {
  data() {
    return {
      detailNews:{},
    };
  },

  computed: {
    ...mapGetters("user", ["getUserInfo"]),
  },

  methods: {
    async initData() {
      const {params} = this.$route;
      try {
        const {data} = await getNewsDetail(params.id);
        this.detailNews = data;
        console.log(data);
      } catch (e) {
        console.log(e);
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },

  created() {
    this.initData();
  },
};
</script>
<style>
.el-dialog__body {
  max-height: 60vh;
  overflow-y: auto;
}
.avatar-uploader .el-upload {
  border: 1px dashed #eee;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.avatar-uploader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #8c939d;
  width: 90px;
  height: 90px;
  text-align: center;
}
</style>
<style scoped lang="scss">
.avatar-uploader .avatar {
  width: 90px;
  height: 90px;
  display: block;
}
.community-detail {
  background-color: #f4f8ff;
  padding: 32px 0;

  h1,
  h2,
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  &-header {
    padding: 32px 24px;
    background-color: white;
    border-radius: 6px;
    width: 1152px;
    margin: 0 auto;
    display: flex;

    &-left {
      width: 100px;

      img {
        width: 100px;
        height: 100px;
        margin-bottom: 16px;
      }
    }

    &-right {
      flex: 1;
      margin-left: 32px;

      .top {
        display: flex;
        justify-content: space-between;

        h1 {
          font-size: 24px;
          font-weight: bold;
          color: #262c33;
        }

        h2 {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          color: #262c33;
          margin-top: 16px;

          img {
            height: 38px;
          }

          span {
            margin: 0 18px 0 8px;
          }
        }

        &-right {
          display: flex;

          div {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            padding: 10px;
            height: 46px;
            border: 1px solid #eee;
            border-radius: 6px;

            p {
              display: flex;
              align-items: center;
              font-size: 16px;
              color: #aaaeb3;
              margin: 0;
              line-height: 16px;
            }

            i {
              margin-left: 14px;
              color: #aaaeb3;
            }

            .blue {
              color: #3a89fe;
            }
          }

          div + div {
            margin-left: 12px;
          }
        }
      }

      .bottom {
        margin-top: 16px;
        font-size: 18px;
        color: #262c33;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }

  &-tabs {
    padding: 32px 24px;
    background-color: white;
    border-radius: 6px;
    width: 1152px;
    margin: 24px auto 0;

    .info {
      font-size: 18px;
      color: #262c33;

      p + p {
        margin-top: 40px;
      }

      img {
        display: block;
        width: 800px;
        margin: 32px auto 0;
      }
    }

    .members,
    .success {
      &-cell {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #eee;

        img {
          width: 100px;
          height: 100px;
          margin-right: 32px;
        }

        h1 {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: bold;
          color: #262c33;

          .el-tag {
            margin-left: 8px;
          }

          span {
            font-size: 14px;
            color: #666;
            margin-left: 40px;
          }
        }

        p {
          display: flex;
          flex-wrap: wrap;
          font-size: 18px;
          color: #262c33;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-top: 8px;

          span {
            font-weight: bold;
          }

          .el-tag + .el-tag {
            margin-left: 12px;
          }

          img {
            width: 300px;
            height: 178px;
          }
        }

        p + p {
          margin-top: 16px;
        }
      }

      &-more {
        padding: 24px 0;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #3a89fe;
      }
    }
  }
}
</style>
